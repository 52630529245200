import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
import {
  IAccount, IAuth0User, ILifecycle, IProduct, ISpace
} from '../../../model'
import { getDownloadFilename } from '../tools'
import { getPdfDefinition } from './pdf-definition'
import { pdfTheme } from '../../constants/theme'
import { castNumberToDisplayValue } from '../decimal'

pdfMake.vfs = pdfFonts.pdfMake.vfs

type TGenerateInsightsPdf = {
  doughnut?: any
  datasetBackground?: string[]
  user?: IAuth0User
  space?: ISpace
  account?: IAccount
  entity?: IProduct | ILifecycle
  totalImpact?: any
  legend?: any
  singleScoreLabel?: string,
  noUnitLabel?: string
}

const cropString = (value: string, threshold: number) => (value.length > threshold ? `${value.slice(0, threshold)}…` : value)

export const generateInsightsPdf = ({
  doughnut,
  user = {},
  space = {},
  account,
  entity = {},
  totalImpact = {},
  legend,
  singleScoreLabel,
  noUnitLabel
}: TGenerateInsightsPdf, cb = () => {}) => {
  const displayedImpactCategory = space?.useMethodTotal ? singleScoreLabel : space?.impactCategory?.name

  const data = {
    accountName: account?.name,
    accountNameCropped: account?.name && cropString(account?.name, 50),
    amount: entity?.amount || '1',
    date: moment().format('DD-MM-YYYY'),
    legend,
    doughnut: doughnut.toBase64Image(),
    name: entity?.name && cropString(entity?.name, 60),
    unit: entity?.unit,
    description: entity?.description && cropString(entity?.description, 280),
    totalImpact: {
      amount: castNumberToDisplayValue({ value: totalImpact.amount }),
      unit: totalImpact.unit || noUnitLabel
    },
    impactCategory: space?.nwSet ? `${displayedImpactCategory} - ${space?.nwSet.name}` : displayedImpactCategory,
    impactMethod: space?.impactMethod?.name,
    userEmail: user?.email
  }

  const fileName = getDownloadFilename({
    value: String(entity?.name), extension: 'pdf', prefix: 'Impact insights_', addDate: true
  })
  const docDefinition:any = getPdfDefinition(data)
  pdfMake.createPdf(docDefinition, {}, pdfTheme.fonts).download(fileName, cb)
}
