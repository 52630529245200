import { DoughnutWhiteCircleImage, EcochainLogoImage, LegendBulletImage } from '../../../assets/pdf-svgs'

const getAmountText = (amount: any, style: any = {}) => {
  const [ value, exponent ] = amount || []

  if (!exponent) {
    return {
      text: value, style: style?.default
    }
  }

  return [
    { text: `${value}  · 10`, style: style.sn },
    { text: String(exponent).replace('+', ''), sup: { offset: '30%', fontSize: 8 }, style: style.sn }
  ]
}

export const getLeftSection = (data: any) => {
  const {
    amount, unit, name, description, impactCategory, totalImpact: { amount: totalAmount, unit: totalUnit }
  } = data || {}
  const rows:any = []
  let rowIndex = 0

  data.legend.forEach((item: any, index: number) => {
    if (index > 0 && index % 5 === 0) rowIndex = 0

    if (!rows[rowIndex]) rows[rowIndex] = []

    const styledSvg = {
      svg: LegendBulletImage.replace('fill="#ffffff"', `fill="${item.color}"`),
      alignment: 'right'
    }
    const styledName = {
      text: item.name
    }
    const styledPerc = {
      text: `${item.perc}%`,
      bold: true,
      alignment: 'right'
    }

    rows[rowIndex].push(styledSvg, styledName, styledPerc)
    rowIndex += 1
  })

  rows.forEach((row: any) => {
    if (row.length === 3) row.push({ text: '' }, { text: '' }, { text: '' })
  })

  const table = {
    headerRows: 0,
    widths: [ 10, 95, 45, 30, 95, 45 ],
    body: rows
  }

  return [
    {
      svg: EcochainLogoImage,
      width: 120,
    },
    {
      text: 'Product footprint of',
      style: 'subheader',
      margin: [ 0, 50, 0, 0 ]
    },
    {
      text: name,
      style: 'header',
      margin: [ 0, 0, 0, 8 ]
    },
    {
      text: `Unit: ${amount} ${unit}\n\n`
    },
    {
      text: `${description}\n\n`
    },
    {
      text: [
        'The impact on ',
        impactCategory,
        ' is ',
        {
          text: getAmountText(totalAmount, { default: 'rubikHeavy', sn: 'rubikHeavy' })
        },
        {
          text: ` ${totalUnit}`,
          style: 'rubikHeavy'
        },
        '. The graph shows a breakdown of the highest contributors.\n\n'
      ]
    },
    {
      layout: {
        defaultBorder: false,
        paddingTop() { return 6 },
        paddingBottom() { return 6 },
      },
      style: 'legend',
      margin: [ 0, 8, 0, 0 ],
      table
    }
  ]
}

export const getRightSection = (data: any) => [
  {
    text: data.accountNameCropped,
    style: 'subheader',
    fontSize: 16,
    alignment: 'right',
    margin: [ 40, 0, 0, 0 ]
  },
  {
    text: data.date,
    style: 'navy',
    fontSize: 10,
    alignment: 'right',
  },
  {
    text: `* Impact category: ${data.impactCategory}\n\n* Method: ${data.impactMethod}`,
    style: 'navy',
    fontSize: 10,
    absolutePosition: {
      x: 660,
      y: 400
    }
  },
]

export const getGraphSection = (data: any) => {
  const { totalImpact: { amount: totalAmount, unit: totalUnit } } = data || {}
  const [ coefficient, exponent ] = totalAmount

  return [
    {
      svg: DoughnutWhiteCircleImage,
      relativePosition: {
        x: 0,
        y: 0
      }
    },
    {
      image: data.doughnut,
      width: 281,
      alignment: 'center',
      relativePosition: {
        x: 0,
        y: 0
      }
    },
    {
      columns: [
        {
          width: 160,
          height: 160,
          relativePosition: {
            x: 100,
            y: coefficient && exponent ? 115 : 100
          },
          stack: [
            {
              text: getAmountText(totalAmount, { default: 'graphAmount', sn: 'graphAmountSn' }),
              alignment: 'center',
              fontSize: 50,
              lineHeight: 0.9
            },
            {
              text: `${totalUnit}*`,
              alignment: 'center',
              fontSize: 20
            }
          ]
        }
      ]
    }
  ]
}

export const getFooterSection = (data: any) => [
  {
    text: 'Made with Mobius',
    style: 'footerTitle'
  },
  {
    text: 'ecochain.com/mobius',
    link: 'https://ecochain.com/mobius',
    style: 'link'
  },
  {
    text: [
      {
        text: 'Disclaimer',
        style: 'rubikHeavy'
      },
      {
        text: ` This document contains confidential and proprietary business information of ${data.accountName}. It may only be printed or (photo) copied or otherwise used with the written consent of ${data.userEmail}. Care must be taken when comparing results of different LCA studies. Please consult an LCA practitioner and "Requirements for comparability" as defined in ISO 14025:2020 Clause 6.7.2. for comparative assertions.`
      }
    ],
    alignment: 'justify',
    margin: [ 0, 12, 20, 0 ],
    width: 500,
    style: 'footerText'
  }
]
